<template>
    <div class="row g-5 g-xxl-8">
    <div class="col-xl-6">
        
        <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">ETK İzni</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Form-->
      <Form
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
        @submit="saveChanges1()"
        :validation-schema="profileDetailsValidator"
      >
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          

          

          <!--begin::Input group-->
          <div class="row mb-9">
            <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 fw-bold"  v-if="profile.flags.advertisementFlag">
                <p class="fs-6 text-gray-600">
                    Kullanıcı ETK izni vermiş. İzni kaldırmak için, aşağıdaki  onay kutucuğunu tıklayıp <strong>"ETK İzni Kaldır"</strong>  butonuna basınız.
                </p>
                
            </div>
            <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 fw-bold" v-if="!profile.flags.advertisementFlag">
                <p class="fs-6 text-gray-600">
                    Kullanıcı ETK izni vermemiş. ETK izninin panel aracılığı ile verilmesi mümkün değildir.
                </p>
                
            </div>
          </div>
          <!--end::Input group-->
          <!--begin::Form input row-->
          <div class="form-check form-check-solid fv-row" v-if="profile.flags.advertisementFlag">
            <input
              name="deactivate"
              class="form-check-input"
              type="checkbox"
              value=""
              v-model="confirmDisableAdvertisement"
              id="deactivate"
            />
            <label class="form-check-label fw-bold ps-2 fs-6" for="deactivate" >ETK iznini kaldırmak istiyorum</label>
            <br>
            <span class="text-danger">İzni kaldırdıktan sonra, admin paneli üzerinden tekrar eski haline getirmeniz mümkün değildir.</span>
          </div>
          <!--end::Form input row-->
        </div>
        <!--end::Card body-->

        <!--begin::Actions-->
        <div class="card-footer d-flex justify-content-end py-6 px-9" v-if="profile.flags.advertisementFlag">
          

          <button
            @click.prevent="disableAdvertisement()"   
            type="submit"
            id="kt_account_profile_details_submit"
            ref="submitButton1"
            class="btn btn-primary">
            <span class="indicator-label"> ETK İzni Kaldır </span>
            <span class="indicator-progress">
              Lütfen bekleyin...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Actions-->
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
        

    </div>
    <div class="col-xl-6">
        
        <!--begin::Sign-in Method-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
    >
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Puanı</h3>
      </div>
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_signin_method" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9">
        
          <!--begin::Input group-->
          <div class="row mb-6">
            
            <label class="col-lg-4 col-form-label fw-bold fs-6">Mevcut Puan</label>
           
            <div class="col-lg-8 fv-row d-flex align-items-center fs-3 fw-bolder">
                {{profile.score}}
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
          
          <!--begin::Input group-->
          <div class="row mb-6">
            
            <label class="col-lg-4 col-form-label fw-bold fs-6">Eklenecek Puan</label>
           
            <div class="col-lg-8 fv-row">
              <input type="number" v-model="score" class="form-control form-control-lg form-control-solid"/>
              <span class="text-muted">(-) Değer girerek puan eksiltebilirsiniz.</span>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          
        
      </div>
      <!--end::Card body-->

      <!--begin::Actions-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          

          <button
            type="submit"
            id="kt_account_profile_details_submit"
            ref="submitButton1"
            @click.prevent="updateScore()"
            class="btn btn-primary">
            <span class="indicator-label" v-show="!scoreUpdating"> Puanı Güncelle </span>
            <span class="" v-show="scoreUpdating">
              Lütfen bekleyin...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Actions-->


    </div>
    <!--end::Content-->
  </div>
  <!--end::Sign-in Method-->
        


    </div>
    </div>

    <div class="row g-5 g-xxl-8">
    
    <div class="col-xl-12">
        <!--begin::Deactivate Account-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_deactivate"
      aria-expanded="true"
      aria-controls="kt_account_deactivate"
    >
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">Kullanıcıyı Sil</h3>
      </div>
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_deactivate" class="collapse show">
      <!--begin::Form-->
      <form
        id="kt_account_deactivate_form"
        class="form"
        
      >
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <div
            class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6"
          >
            <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
              <inline-svg src="media/icons/duotune/general/gen044.svg" />
            </span>
            <!--begin::Wrapper-->
            <div class="d-flex flex-stack flex-grow-1">
              <!--begin::Content-->
              <div class="fw-bold">
                <h4 class="text-gray-800 fw-bolder">
                  Uyarı!
                </h4>

                <div class="fs-6 text-gray-600">
                  İşleme devam etmeniz halinde, kullanıcıya ait bilgiler sistemden silinecektir. Bu işlemin geri dönüşü yoktur. Kullanıcıyı silmek istediğinizden emin değilseniz, yandaki formdan kullanıcıyı pasif olarak işaretleyebilirsiniz. 
                </div>
              </div>
              <!--end::Content-->
            </div>
            <!--end::Wrapper-->
          </div>

         
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button
            @click.prevent="removeUser()"
            id="kt_account_deactivate_account_submit"
            ref="submitButton5"
            type="button"
            class="btn btn-danger fw-bold"
          >
            <span class="indicator-label"> Kullanıcıyı sil </span>
            <span class="indicator-progress">
              Lütfen bekleyin...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
        <!--end::Card footer-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Deactivate Account-->
    </div>
    </div>

  

  

  

  

  

  
</template>

<script>

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import api from '@/core/services/ApiService'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  name: "account-settings",
  props:['profile'],
  data(){
    return {
      score:null,
      scoreUpdating:false,
      confirmDisableAdvertisement:false,
      userStatus:null,
    }
  },
  components: {
    
  },
  methods:{
    deactiveUser(){
      Swal.fire({
        title:'Kullanıcı Silme',
        icon:'warning',
        text:'Kullanıcıyı silmek istediğinize emin misiniz?',

      })
    },
    removeUser(){
      Swal.fire({
        title:'Kullanıcı Silme',
        icon:'warning',
        text:'Kullanıcıyı silmek istediğinize emin misiniz? Bu işlemin geri dönüşü yoktur!',
        confirmButtonText:'Evet, silmek istiyorum',
        showCancelButton:true,
        cancelButtonText:'Vazgeç'
      }).then((res)=>{
        if(res.isConfirmed){
          api.get('/User/DeleteUser/'+this.profile.userId).then((response)=>{
            Swal.fire({
              title:'Kullanıcı Silme',
              text:'Kullanıcı Silindi',
              icon:'success',
            }).then(()=>{
              window.location.href='/clients';
            });
          })
        }
      });
    },

    updateUserStatus(){
      let newStatus = this.$refs.userStatus.checked ? 'AC' : 'DA';      
      api.post('/User/UpdateUser/',{status:newStatus, userId:this.profile.userId}).then((res)=>{
        Swal.fire({
            text: "Kullanıcının statusu değiştirildi. \"Tamam\" butonuna basarak sayfayı yenileyebilirsiniz.",
            icon: "success",
            confirmButtonText: "Tamam",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            location.reload(true);
          });
      });
    },

    disableAdvertisement(){
      if(!this.confirmDisableAdvertisement){
        alert('Lütfen ETK iznini kaldırmak istediğinizi onaylayınız');
      }else{
        api.get('/User/DisableAdvertisement/'+this.profile.userId).then((res)=>{
          Swal.fire({
            text: "Kullanıcının ETK izni kaldırıldı. \"Tamam\" butonuna basarak sayfayı yenileyebilirsiniz.",
            icon: "success",
            confirmButtonText: "Tamam",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            location.reload(true);
          });
        });
      }
    },
    updateScore(){
      this.scoreUpdating=true;
      api.get('/User/UploadScore/'+this.profile.userId+'/'+this.score).then(()=>{
        this.scoreUpdating=false;
        Swal.fire({
            text: "Kullanıcının puanı güncellendi. \"Tamam\" butonuna basarak sayfayı yenileyebilirsiniz.",
            icon: "success",
            confirmButtonText: "Tamam",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            location.reload(true);
          });
      });
    }
  },
  mounted(){
    
       setCurrentPageBreadcrumbs("Settings", ["Account"]);
  }
  };
</script>
